<template>
  <div>
    <div class="my-2">
      <!-- Table Top -->

      <div class="tw-flex tw-items-center tw-gap-[12px] tw-justify-between">
        <!-- Search -->
        <search-input
          :placeholder="'Cari Event berdasarkan Nama...'"
          :onInput="onSearch"
        />
        <b-button
          v-b-modal.add-event
          style="border-radius: 15px; height: 48px"
          variant="primary"
        >
          <span class="text-sm text-nowrap">Tambah Event</span>
        </b-button>
      </div>
    </div>

    <!-- Table Contianer Card -->
    <b-card no-body class="mb-0">
      <b-table
        :busy="isLoading"
        class="position-relative"
        :items="data"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        striped
        hover
        empty-text="Data tidak ditemukan"
      >
        <template #table-busy>
          <div class="my-2 text-center text-danger">
            <b-spinner class="mr-1 align-middle"></b-spinner>
            <strong>Sedang memuat data...</strong>
          </div>
        </template>
        <template #cell(created_at)="data">
          <p>
            {{ formatDate(data.value) }}
          </p>
        </template>

        <template #cell(updated_at)="data">
          <p>
            {{ formatDate(data.value) }}
          </p>
        </template>

        <template #cell(harga)="data">
          <p>
            {{ rupiah(data.value) }}
          </p>
        </template>

        <template #cell(harga_diskon)="data">
          <p>
            {{ rupiah(data.value) }}
          </p>
        </template>

        <template #cell(status)="data">
          <p>
            {{ !!data.value ? "Aktif" : "Non-aktif" }}
          </p>
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              :to="{
                name: 'manajemen-event-detail',
                params: { id: data.item.id },
              }"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">Detail Event</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="setTargetedId(data.item.id)"
              v-b-modal.edit-event
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="setTargetedId(data.item.id)"
              v-b-modal.delete-event
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <pagination
          :from="from"
          :to="to"
          :pageTotal="totalPage"
          :total="totalData"
        />
      </div>
    </b-card>

    <!-- Add Event -->
    <b-modal
      id="add-event"
      size="md"
      centered
      ref="modalProduct"
      title="Tambah Event"
      cancel-title="Close"
      :ok-title-html="`${isLoadingSubmit ? 'Loading..' : 'Save'}`"
      no-close-on-backdrop
      no-close-on-esc
      :ok-disabled="isLoadingSubmit"
      :cancel-disabled="isLoadingSubmit"
      :hide-header-close="isLoadingSubmit"
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="submitEvent"
    >
      <b-form>
        <b-form-group label="Nama Event" label-for="nama">
          <b-form-input
            v-model="formEvent.name"
            id="nama"
            placeholder="Masukkan Nama Event"
          />
        </b-form-group>

        <b-form-group label="Cover" label-for="fileExcel">
          <b-form-file
            id="fileExcel"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            accept=".jpg, .png"
            @change="handleFileUpload"
          />
        </b-form-group>

        <b-form-group label="Deskripsi" label-for="deskripsi">
          <b-form-input
            v-model="formEvent.description"
            id="deskripsi"
            placeholder="Masukkan Deskripsi"
          />
        </b-form-group>

        <div class="tw-flex tw-items-center tw-gap-3">
          <label for="show">Aktif</label>
          <b-form-checkbox
            id="show"
            :checked="formEvent.status"
            v-model="formEvent.status"
            class=""
            name="check-button"
            switch
          />
        </div>
      </b-form>
    </b-modal>

    <!-- Edit Event -->
    <b-modal
      id="edit-event"
      size="md"
      centered
      title="Edit Event"
      cancel-title="Close"
      :ok-title-html="`${isLoadingSubmit ? 'Loading..' : 'Save'}`"
      no-close-on-backdrop
      no-close-on-esc
      :ok-disabled="isLoadingSubmit"
      :cancel-disabled="isLoadingSubmit"
      :hide-header-close="isLoadingSubmit"
      cancel-variant="outline-secondary"
      @show="fetchDetailEvent"
      @hidden="resetModal"
      @ok="editEvent"
    >
      <b-form>
        <b-form-group label="Nama Event" label-for="nama">
          <b-form-input
            v-model="formEvent.name"
            id="nama"
            placeholder="Masukkan Nama Event"
          />
        </b-form-group>

        <b-form-group label="Cover" class="tw-flex tw-justify-center">
          <b-img
            v-if="previewUrl"
            :src="previewUrl"
            width="200px"
            height="200px"
            rounded
            alt="cover"
          ></b-img>
        </b-form-group>

        <b-form-group label="Cover" label-for="cover">
          <b-form-file
            id="cover"
            v-model="formEvent.cover"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            accept=".jpg, .png"
            @change="handleFileUpload"
          />
        </b-form-group>

        <b-form-group label="Deskripsi" label-for="deskripsi">
          <b-form-input
            v-model="formEvent.description"
            id="deskripsi"
            placeholder="Masukkan Deskripsi"
          />
        </b-form-group>

        <div class="tw-flex tw-items-center tw-gap-3">
          <label for="show">Aktif</label>
          <b-form-checkbox
            id="show"
            :checked="formEvent.status"
            v-model="formEvent.status"
            class=""
            name="check-button"
            switch
          />
        </div>
      </b-form>
    </b-modal>

    <!-- Delete Event -->
    <b-modal
      id="delete-event"
      cancel-variant="outline-secondary"
      :ok-title-html="`${isLoadingSubmit ? 'Loading..' : 'Delete'}`"
      no-close-on-backdrop
      no-close-on-esc
      :ok-disabled="isLoadingSubmit"
      :cancel-disabled="isLoadingSubmit"
      :hide-header-close="isLoadingSubmit"
      cancel-title="Close"
      centered
      title="Hapus Event"
      @ok="deleteEvent"
    >
      <b-form>
        <b-form-group>
          <p>Apakah anda akan menghapus Event ini?</p>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import CustomFilter from "@/@core/components/base/CustomFilter.vue";
import SearchInput from "@/@core/components/base/SearchInput.vue";
import { defineComponent, ref, onMounted, watch } from "@vue/composition-api";
import {
  BButton,
  BTable,
  BDropdown,
  BDropdownItem,
  BCard,
  BSpinner,
  BFormInput,
  BForm,
  BFormGroup,
  BFormCheckbox,
  BFormFile,
  BImg,
} from "bootstrap-vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import Pagination from "@/@core/components/base/Pagination.vue";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useRouter, formatDate, rupiah } from "@core/utils/utils";
import debounce from "lodash.debounce";
import vSelect from "vue-select";
import router from "@/router";

import repository from "@repofactory";
const repoEvents = repository.get("EventRepository")

export default defineComponent({
  components: {
    SearchInput,
    BButton,
    CustomFilter,
    BTable,
    BDropdown,
    BDropdownItem,
    FeatherIcon,
    Pagination,
    BCard,
    BSpinner,
    BForm,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormCheckbox,
    BFormFile,
    BImg
  },
  setup() {
    const toast = useToast();
    const { route } = useRouter();

    const data = ref([]);
    const isLoading = ref(false);
    const isLoadingSubmit = ref(false);

    const DEFAULT_FORM_EVENT = {
      name: null,
      description: null,
      cover: null,
      status: true
    }

    const formEvent = ref({
      ...DEFAULT_FORM_EVENT
    });

    const product_id = ref(null);

    const setTargetedId = (value) => {
      product_id.value = value;
    };

    const modalProduct = ref(null);

    const tableColumns = [
      { key: "id", label: "ID" },
      { key: "name", label: "NAMA EVENT" },
      { key: "list_bundle", label: "LIST BUNDLE" },
      { key: "description", label: "DESKRIPSI" },
      { key: "created_at", label: "DIBUAT" },
      { key: "updated_at", label: "DIUBAH" },
      { key: "status", label: "STATUS" },
      { key: "actions" },
    ];

    const keyword = ref("");

    let totalPage = ref(1);
    let totalData = ref(1);
    let from = ref(1);
    let to = ref(1);

    const onSearch = (e) => {
      keyword.value = e.currentTarget.value;
    };

    const showToast = (title, text, variant, icon = "BellIcon") => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    };

    const fetchData = async () => {
      try {
        isLoading.value = true;
        const res = await repoEvents.get(route.value.query.page, 10 ,keyword.value)
        data.value = res.data.data.data;
        totalData.value = res.data.data.total
        from.value = res.data.data.from
        to.value = res.data.data.to
        totalPage.value = res.data.data.last_page
        isLoading.value = false;
      } catch (e) {
        isLoading.value = false;
        if (e.response.data.message) {
          showToast(
            "Error",
            e.response.data.message,
            "danger",
            "AlertTriangleIcon"
          );
        } else {
          showToast(
            "Error",
            "Error Fetching data event",
            "danger",
            "AlertTriangleIcon"
          );
        }

        isLoading.value = false;
      }
    };

    const fetchDetailEvent = async () => {
      try {
        const res = await repoEvents.getDetail(product_id.value);
        const data = res.data.data;

        previewUrl.value = data.cover

        formEvent.value = {
          ...formEvent.value,
          name: data.name,
          status: !!data.status,
          cover: null,
          description: data.description,
        };
      } catch (e) {
        if (e.response.data.message) {
          showToast(
            "Error",
            e.response.data.message,
            "danger",
            "AlertTriangleIcon"
          );
        } else {
          showToast(
            "Error",
            "Error Fetching data event detail",
            "danger",
            "AlertTriangleIcon"
          );
        }
      }
    };

    const submitEvent = async (bvModalEvent) => {
      bvModalEvent.preventDefault();
      try {
        isLoadingSubmit.value = true;
        let formData = new FormData()
        const { name, description, status, cover } = formEvent.value
        formData.append("name", name)
        formData.append("description", description)
        formData.append("cover", cover)
        formData.append("status", status)
      const res = await repoEvents.create(formData);
        fetchData()
        showToast("Notifikasi", res.data.message, "primary");
        bvModalEvent.vueTarget.hide()
      } catch (e) {
        isLoadingSubmit.value = false;
        if (e.response.data.message) {
          showToast(
            "Error",
            e.response.data.message,
            "danger",
            "AlertTriangleIcon"
          );
        } else {
          showToast(
            "Error",
            "Error Submit data Event",
            "danger",
            "AlertTriangleIcon"
          );
        }
      } finally {
        isLoadingSubmit.value = false;
      }
    };

    const editEvent = async (bvModalEvent) => {
      bvModalEvent.preventDefault();
      try {
        isLoadingSubmit.value = true;
        let formData = new FormData()
        const { name, description, status, cover } = formEvent.value
        formData.append("name", name)
        formData.append("description", description)
        if (cover) {
          formData.append("cover", cover)
        }
        formData.append("status", status)
        const res = await repoEvents.update(product_id.value, formData);
        fetchData()
        showToast("Notifikasi", res.data.message, "primary");
        bvModalEvent.vueTarget.hide()
      } catch (e) {
        isLoadingSubmit.value = false;
        if (e.response.data.message) {
          showToast(
            "Error",
            e.response.data.message,
            "danger",
            "AlertTriangleIcon"
          );
        } else {
          showToast(
            "Error",
            "Error Submit data Event",
            "danger",
            "AlertTriangleIcon"
          );
        }
      } finally {
        isLoadingSubmit.value = false;
      }
    };

    const deleteEvent = async (modal) => {
      try {
        isLoadingSubmit.value = true;
        modal.preventDefault();
        const res = await repoEvents.delete(product_id.value);
        showToast("Notifikasi", res.data.message, "primary");
        modal.vueTarget.hide();
        fetchData();
      } catch (e) {
        if (e.response.data.message) {
          showToast(
            "Error",
            e.response.data.message,
            "danger",
            "AlertTriangleIcon"
          );
        } else {
          showToast(
            "Error",
            "Error delete events",
            "danger",
            "AlertTriangleIcon"
          );
        }
      } finally {
        isLoadingSubmit.value = false;
      }
    };

     const previewUrl = ref(null);

    const handleFileUpload = (event) => {
      const file = event.target.files[0];
      if (file) {
        formEvent.value.cover = file;
        previewUrl.value = URL.createObjectURL(file);
      }
    };

    onMounted(() => {
      fetchData();
    });

    watch([keyword], debounce(fetchData, 500));

    const resetModal = () => {
      formEvent.value = {
        ...DEFAULT_FORM_EVENT
      };
    };

    return {
      data,
      keyword,
      tableColumns,
      totalPage,
      totalData,
      from,
      to,
      formEvent,
      isLoading,
      modalProduct,
      isLoadingSubmit,
      product_id,
      previewUrl,

      // method
      onSearch,
      formatDate,
      rupiah,
      resetModal,
      submitEvent,
      editEvent,
      setTargetedId,
      handleFileUpload,
      deleteEvent,
      fetchDetailEvent,
    };
  },
});
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
